.main-cntnt {
  background-color: #000000;
  color: #fff;
  padding: 20px 20px 20px 40px;
}
.main-cntnt h2 {
  color: #fff;
  font-size: 3.5rem;
}
.main-cntnt h1 {
  color: red;
  font-size: 5rem;
  text-align: center;
  font-weight: 600;
}
.main-cntnt p {
  font-size: 1.8rem;
}
.px-20 {
  padding-right: 7.5rem!important;
  padding-left: 7.5rem!important;
}
.btn-red{
  background-color: red;
  color: white;
  margin-bottom: 21px;
}

@media screen and (max-width: 480px) {
  .main-cntnt h1 {
    font-size: 2.5rem;
  }
  .main-cntnt h2 {
    font-size: 1.4rem;
}
.main-cntnt p {
  font-size: 1.3rem;
}
.px-20 {
  padding-right: 4.5rem!important;
  padding-left: 4.5rem!important;
}
.mmabetimage{
  height: 30vh;
}
body {
  background-color: #eff2f5;
}
.main-content {
  padding-top: 42px;
}
}