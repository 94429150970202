@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.social-login {
    display: flex;
}

.google-btn {
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 4px;
  cursor: pointer;
  margin-right:1rem ;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: -7px;
  width: 18px;
  height: 18px;
}
.google-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: #1669f2;
}
.btn.btn-google {
  color: #000!important;
  border: 1px solid #c1bcbc94!important;
  background-color: #fff!important;
}
.btn.btn-google:hover:not(.btn-active), .show>.btn.btn-google {
  border-color: #cd3623;
  background-color: #d3d9f794!important;
}


.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: rgba(0,0,0,0.2);
}

.btn-social {
    position: relative;
    padding-left: 40px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-social:hover {
    color: #eee;
}

.btn-social :first-child {
    position: relative;
    font-size: 1.3em;
    text-align: center;
    border-right: 1px solid rgb(243 243 243)!important;
}
.main-cntnt {
  background-color: #000000;
  color: #fff;
  padding: 20px 20px 20px 40px;
}
.main-cntnt h2 {
  color: #fff;
  font-size: 3.5rem;
}
.main-cntnt h1 {
  color: red;
  font-size: 5rem;
  text-align: center;
  font-weight: 600;
}
.main-cntnt p {
  font-size: 1.8rem;
}
.px-20 {
  padding-right: 7.5rem!important;
  padding-left: 7.5rem!important;
}
.btn-red{
  background-color: red;
  color: white;
  margin-bottom: 21px;
}

@media screen and (max-width: 480px) {
  .main-cntnt h1 {
    font-size: 2.5rem;
  }
  .main-cntnt h2 {
    font-size: 1.4rem;
}
.main-cntnt p {
  font-size: 1.3rem;
}
.px-20 {
  padding-right: 4.5rem!important;
  padding-left: 4.5rem!important;
}
.mmabetimage{
  height: 30vh;
}
body {
  background-color: #eff2f5;
}
.main-content {
  padding-top: 42px;
}
}
